@import '~react-activity-feed/src/styles/index.scss';

// ======================================================================================================
// Mixin Sample
// ======================================================================================================

// This is a demo scss file with "programmable" CSS and some interpolation
// SEE: https://sass-lang.com/documentation/style-rules#interpolation

@mixin define-sumeetcolor($sumeetcolorname, $sumeetcolorvalue) {
    h1.sumeet-#{$sumeetcolorname} {
      font-size: 2px;
      text-transform: uppercase;
      color: #{$sumeetcolorvalue} !important;
    }
  }
  
@include define-sumeetcolor("sumeetpink", "#000");

// ======================================================================================================
// CommentField.scss
// ======================================================================================================

.raf-comment-field {
  display: flex;
  margin: 16px 0;

  /* &__ {} */
  &__group {
    display: flex;
    flex: 1;

    .raf-button {
      height: 100%;
      border-radius: 0px 5px 5px 0px;
      margin: 0;
      text-align: left;
      justify-content: flex-start;

      &:focus {
        box-shadow: inset 0 0 0 3px shade($primary, 10%); // color-mod(var(primary) shade(10%));
      }
    }
  }

  .raf-avatar {
    align-self: center;
  }

  .raf-textarea .raf-textarea__textarea {
    flex: 1;
    resize: none;
    background: #f7f7f7;
    color: $fontColor;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px 0px 0px 5px;
    padding: 10px 22px;
    border: 0;
    margin-left: 0px;
    position: relative;

    &::placeholder {
      color: tint($fontColor, 50%); // color-mod(var(fontColor) tint(50%));
    }

    &:focus {
      border: 1px solid $primary;
    }
  }
}

// ======================================================================================================
// CommentItem.scss
// ======================================================================================================

.raf-comment-item {
  display: flex;

  /* margin: 8px 0; */
  padding: 8px 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);

  &__hashtag {
    color: #095482;
    cursor: pointer;
  }

  &__mention {
    color: #095482;
    cursor: pointer;
  }

  &__link {
    color: $info;
  }
}

.raf-comment-item__content {
  margin: 0 0 0 8px;
  font-size: 14px;

  time {
    color: tint($fontColor, 30%); // color-mod(var(fontColor) tint(30%));
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    color: tint($fontColor, 30%); // color-mod(var(fontColor) tint(30%));
    font-weight: 400;
  }
}

.raf-comment-item__author {
  color: $info;
  font-weight: 700;
}


// ======================================================================================================
// ReactionIcon.scss
// ======================================================================================================


.raf-reaction-icon {
  display: flex;
  align-items: center;
  margin: 4px 0;
  cursor: pointer;

  svg {
    width: 1em;
  }
}

.raf-reaction-icon__image {
  margin-right: 8px;
}

.raf-reaction-icon__label {
  color: $primary;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
}

// New Post Button

.button-add-new-post {
  color: #fff !important;
}

// TEMP for Demo

.rfu-dropzone__notifier, .raf-panel-header {
  display: none !important;
}